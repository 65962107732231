/*==================== Import CSS Global Styles ====================*/
@import url(/src/components/globalStyles.css);

/*==================== BASE ====================*/
*,
*:before,
*:after {
    box-sizing: border-box;
}


/*==================== NFT GRID LIST ====================*/

.nft-grid {
    display: grid;
    gap: 20px;
    padding: 20px;
}

/*==================== PURCHASE CARD ====================*/
.purchase__title {
    font-size: 24px;
    font-family: var(--grotesk-semi-bold);
}

.purchase__container {
    width: 95%;
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.text__content {
    color: var(--black-eno-mid);
}

.nft-purchase-card {
    display: flex;
    flex-direction: column;
    border: 2px solid var(--bleached-aqua);
    border-radius: 22px;
    color: var(--black-eno);
    padding: 16px;
    width: 350px;
    margin: auto;
    margin-bottom: 35px;
    margin-top: 35px;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    align-items: center;
    row-gap: 15px;
    transition: transform 0.3s ease, border-color 0.3s ease;
}

.nft-purchase-card:hover {
    border-color: var(--button-content);
    transform: translate(0, -15px);
}

p.purchase__description {
    font-size: 16px;
    font-family: var(--grotesk-regular);
    margin: 10px 0px;
    line-height: 17px;
    color: var(--black-eno-mid);
}

p.purchase__minted {
    font-family: var(--grotesk-regular);
    font-size: 20px;
    color: var(--button-content);
    font-weight: 600;
}

.nft-purchase-card video {
    border-radius: 16px;
    width: 100%;
}

.nft-purchase-card button {
    width: 100%;
    background-color: var(--shady-glade);
    border-radius: 32px;
    margin-top: 4px;
    padding: 10px 0;
    padding-bottom: 5px;
}

.nft-video-container {
    position: relative;
}

.nft-video-container.blur video {
    filter: blur(10px);
}

.countdown-NFT {
    font-size: 12px;
    color: var(--black-eno-mid);
}

.countdown-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0);
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 25px;
}

.countdown-timer2 {
    font-size: 10px;
    margin-top: -15px;
    color: var(--black-eno-mid)
}

.hero__btn-mint.color-1:disabled {
    background-color: grey;
    cursor: not-allowed;
    pointer-events: none;
}

.hero__btn-wrapper {
    display: inline-block;
}

.hero__btn-wrapper:disabled {
    background-color: grey;
}

.hero__btn-wrapper:hover:disabled {
    background-color: black;
}


/* ======================== MEDIA QUERYS ======================== */
@media (max-width: 767px) {
    .purchase__container-details {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        justify-content: space-between;
        align-items: center;
    }

    .purchase__left-details,
    .purchase__right-details {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nft-grid {
        display: flex;
        flex-direction: column;
    }

    p.purchase__minted-details {
        font-family: var(--grotesk-regular);
        font-size: 15px;
        color: var(--button-content);
        font-weight: 600;
        margin-top: 2px;
    }

    span.purchase__eno-price {
        font-family: var(--grotesk-regular);
        font-size: 20px;
        color: var(--black-eno-mid);
        font-weight: 200;
        margin-top: 5px;
    }

    .purchase__left-details img {
        margin-right: 10px;
        width: 25px;
        height: 25px;
        font-size: 16px;
        font-family: var(--grotesk-regular);
        color: var(--black-eno-mid);
        border: 2px solid var(--black-eno-mid);
        border-radius: 32px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {

    /* .nft-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 0 20px;
    } */

    .purchase__container-details {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        justify-content: space-between;
        align-items: center;
    }

    .purchase__left-details,
    .purchase__right-details {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p.purchase__minted-details {
        font-family: var(--grotesk-regular);
        font-size: 16px;
        color: var(--button-content);
        font-weight: 600;
        margin-top: 2px;
    }

    span.purchase__eno-price {
        font-family: var(--grotesk-regular);
        font-size: 20px;
        color: var(--black-eno-mid);
        font-weight: 200;
        margin-top: 5px;
    }

    .purchase__left-details img {
        margin-right: 10px;
        width: 25px;
        height: 25px;
        font-size: 16px;
        font-family: var(--grotesk-regular);
        color: var(--black-eno-mid);
    }
}

@media (min-width: 1024px) {

    /* .nft-grid-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        padding: 0 20px;
    }  */

    .purchase__container-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .purchase__left-details,
    .purchase__right-details {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p.purchase__minted-details {
        font-family: var(--grotesk-regular);
        font-size: 17px;
        color: var(--button-content);
        font-weight: 600;
        margin-top: 2px;
    }

    span.purchase__eno-price {
        font-family: var(--grotesk-regular);
        font-size: 18px;
        color: var(--black-eno-mid);
        font-weight: 200;
        margin-top: 5px;
    }

    .purchase__left-details img {
        margin-right: 10px;
        width: 25px;
        height: 25px;
        font-size: 16px;
        font-family: var(--grotesk-regular);
        color: var(--black-eno-mid);
        border: 2px solid var(--black-eno-mid);
        border-radius: 32px;
    }
}

.nft-video-container {
    position: relative;
}

.nft-video-container.blur video {
    filter: blur(10px);
}

.countdown-overlay {
    position: absolute;
    width: 80%;
    padding-top: 20px;
    border-radius: 10px;
    background: white;
    color: #009268;
    font-size: 32px;
    font-family: var(--grotesk-medium);
}