/*==================== Import CSS Global Styles ====================*/
@import url(/src/components/globalStyles.css);


.newsletter {
    display: flex;
    justify-content: center;
    margin: 50px 0;
}

.newsletter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-color);
    border-radius: 22px;
}

.newsletter-left {
    width: auto;
    padding: 16px;
}

.newsletter-left img {
    width: 100px;
}

.newsletter-right {
    width: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.newsletter-right h2 {
    font-family: var(--grotesk-bold);
    font-size: 28px;
    color: var(--black-eno);
}

.newsletter-right p {
    color: var(--black-eno);
    font-family: var(--grotesk-regular);
    font-size: 18px;
    text-align: center;
    line-height: 20px;
}

.newsletter-right span {
    display: flex;
    color: var(--black-eno);
    font-size: 15px;
    justify-content: center;
    margin-bottom: 10px;
}

/* =================== Form =================== */

.form-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.form__contact {
    display: flex;
    border-radius: 32px;
    width: fit-content;
    background-color: var(--gray-eno);
    padding: 5px 10px;
    margin-bottom: 20px;
}

input[type=text] {
    color: var(--black-eno-mid);
    border-radius: 32px;
    margin: 10px;
    outline: none;
    width: 100%;
}

button.submit__contact {
    background-color: var(--button-content);
    border: transparent;
    color: var(--main-color);
    border-radius: 32px;
    cursor: pointer;
    font-family: var(--grotesk-bold);
    font-size: 16px;
    width: 280px;
    padding: 16px 0;
    transition: background-color 0.4s, color 0.4s;
    /* Ajustado a 0.4s */
}

button.submit__contact:hover {
    background-color: var(--button-hover);
}


@media (min-width: 1024px) {

    .newsletter {
        display: flex;
        justify-content: center;
    }

    .newsletter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 30px;
        background-color: var(--main-color);
        border-radius: 22px;
        justify-content: center;
        width: 70%;
        margin-bottom: 70px;
    }

    .newsletter-left {
        width: 30%;
        display: flex;
        justify-content: center;
    }

    .newsletter-left img {
        width: auto;
    }

    .newsletter-right {
        width: 60%;
        text-align: left;
    }

    .newsletter-right h2 {
        font-family: var(--grotesk-bold);
        font-size: 45px;
        color: var(--black-eno);
    }

    .newsletter-right p {
        color: var(--black-eno);
        font-size: 18px;
        padding: 0 30px 0 0;
        text-align: left;
    }

    .newsletter-right span {
        color: var(--black-eno);
        font-size: 18px;
    }

    /* =================== Form =================== */

    .form-container {
        margin-top: 20px;
    }

    .form__contact {
        display: flex;
        border-radius: 32px;
        width: fit-content;
        background-color: var(--gray-eno);
        padding: 5px 10px;
        margin-bottom: 20px;
    }

    input[type=text] {
        color: var(--black-eno-mid);
        border-radius: 32px;
        margin: 10px;
        outline: none;
        width: 100%;
    }

    button.submit__contact {
        background-color: var(--button-content);
        border: transparent;
        color: var(--main-color);
        border-radius: 32px;
        cursor: pointer;
        font-family: var(--grotesk-bold);
        font-size: 18px;
        width: 280px;
        padding: 16px 0;
        transition: background-color 0.4s, color 0.4s;
        /* Ajustado a 0.4s */
    }

    button.submit__contact:hover {
        background-color: var(--button-hover);
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .form-container {
        display: flex;
        justify-content: center;
    }

    .form__contact {
        width: fit-content;
    }

    .newsletter-right span {
        display: flex;
        justify-content: center;
        color: var(--black-eno);
        font-size: 16px;
    }

    .newsletter-right p {
        color: var(--black-eno);
        font-family: var(--grotesk-regular);
        font-size: 18px;
        text-align: center;
        line-height: 20px;
        padding: 0 180px;
    }
}