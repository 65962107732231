/*==================== Import CSS Global Styles ====================*/
@import url(/src//components/globalStyles.css);

/*==================== Clases Originales ====================*/

.accordion {
    margin: 50px auto;
    width: 90%;
    max-width: 800px;
    text-align: left;
    color: #fff;
}

.accordionText h3 {
    text-align: center;
    font-family: var(--grotesk-semi-bold);
    font-size: 30px;
}

.faqText {
    list-style: none;
    padding: 0;
    flex-direction: column;
    display: flex;
}

.faqText-alt {
    list-style: none;
    padding: 0;
    flex-direction: column;
    display: flex;
}

.faqText li {
    background-color: var(--light-legion-blue);
    padding: 20px 25px;
    border-radius: 12px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease, max-height 0.6s ease, padding 0.6s ease;
    overflow: hidden;
    cursor: pointer;
}

.faqText-alt li {
    background-color: var(--main-bone);
    padding: 20px 25px;
    border-radius: 12px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease, max-height 0.6s ease, padding 0.6s ease;
    overflow: hidden;
    cursor: pointer;
}

.faqText li.showAnswer {
    padding: 30px 25px;
    max-height: auto;
}

.faqText-alt li.showAnswer {
    padding: 30px 25px;
    max-height: auto;
}

.links__accordion {
    color: #02735e !important;
    font-weight: 900;
}

.questionArrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.question {
    font-size: 20px;
    font-weight: bold;
    white-space: normal;
    color: var(--main-bone);
}

.question-alt {
    font-size: 20px;
    font-weight: bold;
    white-space: normal;
    color: var(--black-eno);
    font-family: var(--grotesk-regular);
}

.arrow {
    transition: transform 0.6s ease-in-out;
    height: 20px !important;
    width: 20px !important;
    font-size: 20px;
    background-color: #02735e;
    border-radius: 32px;
    padding: 5px;
}

.faqText li.showAnswer .arrow {
    transform: rotate(180deg);
}

.faqText-alt li.showAnswer .arrow {
    transform: rotate(180deg);
}

.faqText p {
    margin-top: 10px;
    display: none;
    font-family: var(--grotesk-regular);
}

.faqText-alt p {
    margin-top: 10px;
    display: none;
    font-family: var(--grotesk-regular);
}

.faqText li.showAnswer p {
    display: block;
    color: var(--main-bone);
}

.faqText p a {
    color: var(--chalk-violet-strong);
    text-decoration: underline;
}

.faqText p a:hover {
    color: var(--button-hover);
}

.faqText-alt li.showAnswer p {
    display: block;
    color: var(--black-eno-mid);
}

.faqText-alt p a {
    color: var(--chalk-violet-strong);
    text-decoration: underline;
}

.faqText-alt p a:hover {
    color: var(--button-hover);
}

/*==================== Clases con sufijo "White" ====================*/

.accordionWhite {
    margin: 50px auto;
    width: 90%;
    max-width: 800px;
    text-align: left;
    color: #fff;
}

.accordionTextWhite h3 {
    text-align: center;
    font-family: var(--grotesk-semi-bold);
    font-size: 30px;
}

.faqTextWhite {
    list-style: none;
    padding: 0;
    flex-direction: column;
    display: flex;
}

.faqText-altWhite {
    list-style: none;
    padding: 0;
    flex-direction: column;
    display: flex;
}

.faqTextWhite li {
    background-color: var(--light-legion-blue);
    padding: 20px 25px;
    border-radius: 12px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease, max-height 0.6s ease, padding 0.6s ease;
    overflow: hidden;
    cursor: pointer;
}

.faqText-altWhite li {
    background-color: var(--main-bone);
    padding: 20px 25px;
    border-radius: 12px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease, max-height 0.6s ease, padding 0.6s ease;
    overflow: hidden;
    cursor: pointer;
}

.faqTextWhite li.showAnswerWhite {
    padding: 30px 25px;
    max-height: auto;
}

.faqText-altWhite li.showAnswerWhite {
    padding: 30px 25px;
    max-height: auto;
}

.links__accordionWhite {
    color: #02735e !important;
    font-weight: 900;
}

.questionArrowWhite {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.questionWhite {
    font-size: 20px;
    font-weight: bold;
    white-space: normal;
    color: var(--main-bone);
}

.question-altWhite {
    font-size: 20px;
    font-weight: bold;
    white-space: normal;
    color: var(--black-eno);
    font-family: var(--grotesk-regular);
}

.arrowWhite {
    transition: transform 0.6s ease-in-out;
    height: 20px !important;
    width: 20px !important;
    font-size: 20px;
    background-color: #02735e;
    border-radius: 32px;
    padding: 5px;
}

.faqTextWhite li.showAnswerWhite .arrowWhite {
    transform: rotate(180deg);
}

.faqText-altWhite li.showAnswerWhite .arrowWhite {
    transform: rotate(180deg);
}

.faqTextWhite p {
    margin-top: 10px;
    display: none;
    font-family: var(--grotesk-regular);
}

.faqText-altWhite p {
    margin-top: 10px;
    display: none;
    font-family: var(--grotesk-regular);
}

.faqTextWhite li.showAnswerWhite p {
    display: block;
    color: var(--main-bone);
}

.faqTextWhite p a {
    color: var(--chalk-violet-strong);
    text-decoration: underline;
}

.faqTextWhite p a:hover {
    color: var(--button-hover);
}

.faqText-altWhite li.showAnswerWhite p {
    display: block;
    color: var(--black-eno-mid);
}

.faqText-altWhite p a {
    color: var(--chalk-violet-strong);
    text-decoration: underline;
}

.faqText-altWhite p a:hover {
    color: var(--button-hover);
}

/*==================== MOBILE STYLES ====================*/

/* min-width se usa para aplicar estilos a un elemento 
solo cuando el ancho de la pantalla es igual o mayor 
al valor especificado. */

@media (min-width: 1024px) {
    .links__accordion {
        color: var(--golden-kiwi) !important;
        font-weight: 900;
    }

    .accordion {
        width: 100%;
        padding: 10px;
    }

    .accordionText h3 {
        font-size: 40px;
    }

    .faqText li {
        background-color: var(--light-legion-blue);
        padding: 20px 25px;
        border-radius: 12px;
        margin-bottom: 10px;
        transition: background-color 0.3s ease, max-height 0.6s ease, padding 0.6s ease;
        overflow: hidden;
        cursor: pointer;
    }

    .faqText-alt li {
        background-color: var(--light-legion-blue);
        padding: 20px 25px;
        border-radius: 12px;
        margin-bottom: 10px;
        transition: background-color 0.3s ease, max-height 0.6s ease, padding 0.6s ease;
        overflow: hidden;
        cursor: pointer;
    }

    .faqText li.showAnswer {
        padding: 30px 25px;
        max-height: 1000px;
        /* Ajusta esto según la cantidad de contenido */
    }

    .question {
        font-size: 20px;
        color: var(--main-bone);
    }

    .question-alt {
        font-size: 20px;
        color: var(--main-bone);
    }

    .faqText-alt li.showAnswer p {
        font-size: 18px;
        color: var(--main-color);
    }

    .faqText li.showAnswer p {
        color: var(--main-bone);
    }

    .faqText p {
        font-size: 18px;
        font-family: var(--grotesk-regular);
    }

    .arrow {
        width: 40px;
        height: 40px;
        padding: 10px;
    }

    /*==================== Media Queries con sufijo "White" ====================*/
    .accordionWhite {
        width: 100%;
        padding: 10px;
    }

    .accordionTextWhite h3 {
        font-size: 40px;
        color: var(--shady-glade);
    }

    .faqTextWhite li {
        background-color: var(--light-legion-blue);
        padding: 20px 25px;
        border-radius: 12px;
        margin-bottom: 10px;
        transition: background-color 0.3s ease, max-height 0.6s ease, padding 0.6s ease;
        overflow: hidden;
        cursor: pointer;
    }

    .faqText-altWhite li {
        background-color: var(--main-bone);
        padding: 20px 25px;
        border-radius: 12px;
        margin-bottom: 10px;
        transition: background-color 0.3s ease, max-height 0.6s ease, padding 0.6s ease;
        overflow: hidden;
        cursor: pointer;
    }

    .faqTextWhite li.showAnswerWhite {
        padding: 30px 25px;
        max-height: 1000px;
    }

    .questionWhite {
        font-size: 20px;
        color: var(--main-bone);
    }

    .question-altWhite{
        font-size: 20px;
        color: var(--black-eno);
    }

    .faqText-altWhite li.showAnswerWhite p{
        font-size: 18px;
        color: var(--black-eno-mid);
    }

    .faqTextWhite li.showAnswerWhite p{
        color: var(--main-bone);
    }

    .faqTextWhite p {
        font-size: 18px;
        font-family: var(--grotesk-regular);
    }

    .arrowWhite{
        width: 40px;
        height: 40px;
        padding: 10px;
    }
}


/*==================== MEDIA Query ====================*/
/* Se usa para aplicar estilos a un elemento solo cuando el ancho de la pantalla es igual o menor al valor especificado. */
@media (max-width:1024px) {}