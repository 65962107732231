/*==================== Import CSS Global Styles ====================*/
@import url(/src/components/globalStyles.css);

/*==================== NFTS ====================*/
.nft-card {
    border: 2px solid var(--bleached-aqua);
    border-radius: 16px;
    color: var(--black-eno);
    padding: 16px;
    width: 100%;
    max-width: 350px;
    margin: 35px auto;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    transition: transform 0.3s ease;
}

.nft-card:hover {
    transform: translate(0, -15px);
    border: 2px solid var(--button-content);
}

.nft-card video,
.nft-image {
    border-radius: 16px;
    width: 100%;
    height: auto;
}

.nft-info {
    width: 95%;
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; /* Asegúrate de que los elementos estén distribuidos uniformemente */
}

.nft-details p {
    margin: 0;
}

.nft-name {
    font-size: 25px;
    font-weight: bold;
    font-family: var(--grotesk-semi-bold);
    color: var(--black-eno);
}

.nft-token-id {
    font-size: 20px;
    font-family: var(--grotesk-regular);
    font-weight: bold;
    color: var(--button-content);
}

p.nft-description {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    font-size: 16px;
    font-family: var(--grotesk-regular);
    margin-bottom: 0px;
    line-height: 17px;
    color: var(--black-eno-mid);
}

.nft-id-individual {
    color: var(--black-eno-mid);
    font-weight: var();
}

.hero__btn-mint {
    background-color: var(--button-content);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    align-self: flex-end; /* Asegura que el botón esté al final */
    margin-top: 10px; /* Agrega un margen superior para crear espacio */
}

.hero__btn-mint:hover {
    background-color: var(--button-hover);
}

/*==================== MEDIA QUERIES ====================*/
@media (max-width: 1024px) {

    .nft-card {
        border: 1px solid #ccc;
        width: 100%;
    }

    .nft__info-data{
        margin-top: 10px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .nft-card {
        border: 1px solid #ccc;
        width: 100%;
        height: auto;
    }
}

@media (max-width: 600px) {

}
