/* Importamos estilos globales si es necesario */
@import url(/src/components/globalStyles.css);

/* Estilos por defecto */
.badge__video {
  display: inline;
  width: 70%;
}

.badge__video__alt {
  display: inline;
}

.badge__contents {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    width: 100%;
    display: flex;
}

.badge__video__alt .videoContainer {
  width: 70%;
}

.badge__video-mobile {
  display: inline;
}

.section__titleNFT {
  display: inline;
  font-size: 30px;
  white-space: pre-wrap;
  font-family: var(--grotesk-bold);
  -webkit-background-clip: text;
  color: var(--black-eno);
  margin-bottom: 10px;
}

.section__titleBadge {
  display: inline;
  font-size: 30px;
  white-space: pre-wrap;
  font-family: var(--grotesk-bold);
  -webkit-background-clip: text;
  color: var(--main-color);
  margin-bottom: 10px;
}

.span__subtitleBadge__alt{
  color: var(--black-eno-mid);
}

.span__subtitleBadge {
  color: var(--main-color);
  font-family: var(--grotesk-regular);
  font-size: 20px;
  padding: 10px 0;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 20px;
  /* Añadido para separación */
}

.badge__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  width: 450px;
}

.videoContainer {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* Oculta el exceso de imagen que se salga del contenedor */
}

.videoContainer video {
  border-radius: 32px;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  z-index: 0;
}

.badge__videoDetailView,
.nftsDetaiView,
.badge__video__altDetailView{
  display: none;
}



.badgeCover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  top: 0;
  left: 0;
}

/* Botón para todos los dispositivos */
.hero__btn {
  cursor: pointer;
  background-size: 300% 100%;
  width: 250px;
  font-size: 25px;
  font-weight: 800;
  font-family: var(--grotesk-bold);
  color: var(--main-color);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.4s ease-in-out;
  margin: 20px 0;
  display: inline-block;
}

.hero__btn a {
  text-decoration: none;
  color: var(--main-color);
}

.hero__btn:hover {
  background-position: 100% 0;
  transition: all 0.4s ease-in-out;
}

.hero__btn:focus {
  outline: none;
}

.hero__btn.color1 {
  background-color: transparent;
  border: 2px solid #fff;
  font-size: 22px;
}

.hero__btn.color1:hover {
  background-image: linear-gradient(to right,
      var(--button-hover),
      var(--bleached-aqua),
      var(--legion-blue));
  box-shadow: 0 0 15px 0 var(--golden-kiwi);
  border: 2px solid var(--golden-kiwi);
}

/* Estilos para tablets (iPad) */
@media (min-width: 768px) and (max-width: 1024px) {
  .badge__video__alt .videoContainer {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* Aseguramos que el contenedor cubra toda el área disponible */
  }

  .badge__video__alt .videoContainer video {
    width: 100%;
    /* Ajustar el tamaño del video para iPad */
    height: auto;
    border-radius: 32px;
    /* Aplicamos el mismo radio que la imagen para uniformidad */
  }

  .badge__video__alt .badgeCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Aseguramos que la imagen cubra el video en iPad */
    height: 100%;
    z-index: 2;
    /* Aseguramos que esté encima del video */
    object-fit: cover;
  }

  .badge__video__alt .badge___left {
    width: 80%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* Centramos el contenido en tablets */
  }

  .badge__video__alt .badge___right {
    width: 80%;
    margin-top: 20px;
    text-align: center;
    /* Centramos el texto para tablets */
    display: flex;
    flex-direction: column;
    /* Colocamos el texto en columna */
    align-items: center;
    /* Alineamos el texto y el botón al centro */
  }

  .badge__video__alt .badge__contents {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    /* Centramos el contenido en tablets */
    padding: 20px;
    /* Añadimos un poco de padding para una mejor visualización */
  }

  .badge__video__alt .section__titleBadge {
    font-size: 28px;
    /* Ajustamos el tamaño del texto para tablets */
    margin-bottom: 10px;
    /* Añadimos un poco de margen inferior para separar el título */
  }

  .badge__video__alt .span__subtitleBadge {
    font-size: 18px;
    /* Ajustamos el tamaño del texto para tablets */
    line-height: 1.5;
    /* Mejoramos la legibilidad */
    margin-bottom: 20px;
    /* Añadimos espacio entre el subtítulo y el botón */
  }

  .badge__video__alt .hero__btn {
    width: auto;
    /* Ajustamos el ancho del botón para que se ajuste al contenido */
    font-size: 22px;
    /* Ajustamos el tamaño del texto del botón */
    padding: 10px 20px;
    /* Añadimos padding para un mejor tacto */
  }
}

/* Estilos para desktops */
@media (min-width: 1024px) {
  
  .badge__videoDetailView,
  .nftsDetaiView{
    display: inline;
  }
  
  .badge__video,
  .badge__video__altDetailView {
    display: inline;
  }

  .badge__video-mobile {
    display: none;
  }

  .badge__contents {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    max-width: 100%;
    padding-top: 50px;
    margin-bottom: 50px;
    width: 100%;
  }

  /* Lado izquierdo */
  .badge___left {
    width: auto;
  }

  .videoContainer video {
    border-radius: 32px;
    width: 350px;
    height: auto;
    max-width: 100%;
    position: relative;
    z-index: 0;
  }

  /* Lado derecho */
  .badge__right {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding: 0;
  }

  .span__subtitleBadge {
    color: var(--main-color);
    white-space: 1.5px;
    font-family: var(--grotesk-regular);
    font-size: 20px;
    padding: 10px 0;
    line-height: 1.5;
    text-align: left;
    margin-bottom: 20px;
  }

  .badge__video {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .badge__video-mobile {
    display: none;
  }

  .badge__video__alt .videoContainer {
    width: 100%;
  }

  .span__subtitleBadge__alt {
    color: var(--black-eno-mid);
    font-family: var(--grotesk-regular);
    font-size: 20px;
    padding: 10px 0;
    white-space: pre-wrap;
    line-height: 1.5;
    display: block;
  }


  .videoContainer {
    position: relative;
    width: 100%;
    height: auto;
  }

  .badgeCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }

  .badgeLeft {
    position: relative;
    z-index: 0;
    width: 100%;
    height: auto;
  }
}

/* Estilos específicos para mobile */
@media (max-width: 767px) {
  .badge__video__alt .videoContainer {
    position: relative;
    width: 70%;
  }

  .badge__video__alt .videoContainer video {
    width: 100%;
    height: auto;
  }

  .badge__video__alt .badgeCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    /* Aseguramos que esté encima del video */
    object-fit: cover;
  }

  .badge__video__alt .badge___left {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .badge__video__alt .badge___right {
    width: 100%;
    margin-top: 20px;
  }

  .badge__video__alt .badge__contents {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
}