/*==================== Import CSS Global Styles ====================*/
@import url(/src/components/globalStyles.css);

/*==================== BADGE MINT CARD ====================*/
.hero__title {
    color: var(--main-color);
    font-size: 25px;
    text-align: center !important;
    font-family: var(--grotesk-bold);
    /* background-image: linear-gradient(to right, var(--chalk-violet) 30%, var(--bleached-aqua) 40%, var(--contract-color) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.hero__btn:focus {
    outline: none;
}

.hero__btn a {
    text-decoration: none;
    color: var(--main-color);
}

.hero__btn:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.hero__btn.color-1 {
    background-color: transparent;
    border: 2px solid #fff;
}

.hero__btn.color-1:hover {
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
}

.hero__btn {
    cursor: pointer;
    border: none;
    background-size: 300% 100%;
    width: 350px;
    font-size: 25px;
    font-weight: 800;
    font-family: var(--grotesk-bold);
    color: var(--main-color);
    padding: 12px 0;
    padding-top: 16px;
    text-align: center;
    text-decoration: none;
    border-radius: 50px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.connect-container,
.install-metamask-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.connect-message {
    margin-top: 10px;
    color: var(--black-eno);
    font-family: var(--grotesk-bold);
    font-size: 18px;
}

.badge-mint-card {
    border: 2px solid var(--bleached-aqua);
    border-radius: 16px;
    color: var(--black-eno);
    padding: 16px;
    width: 100%;
    max-width: 350px;
    margin: auto;
    margin-bottom: 35px;
    margin-top: 35px;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    transition: transform 0.3s ease;
}

.nft-preview {
    width: 100%;
    border-radius: 16px;
}

/*==================== BUTTONS ====================*/

.hero__btn-mint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: 300% 100%;
    background-color: var(--button-content);
    border: 2px solid var(--main-color);
    border-radius: 32px;
    color: var(--main-color);
    cursor: pointer;
    font-family: var(--grotesk-regular);
    font-size: 22px;
    font-weight: 800;
    outline: none;
    padding: 10px 0;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    width: 300px;
}

.hero__btn-mint:hover {
    background-position: 100% 0;
    transition: all 0.4s ease-in-out;
    color: var(--main-color);
}

.hero__btn-mint.color-1:hover {
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
}

p.install-message {
    font-size: 18px;
    font-family: var(--grotesk-regular);
    color: var(--black-eno);
    margin-top: 20px;
}

button.install-metamask-container {
    background-color: var(--button-content);
    font-family: var(--grotesk-bold);
    font-size: 18px;
    padding: 10px 10px;
    border-radius: 32px;
    width: 200px;
}

button.connect__container-install {
    background-color: var(--button-content);
    font-family: var(--grotesk-bold);
    font-size: 18px;
    padding: 10px 10px;
    border-radius: 32px;
    width: 200px;
}

@media (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) {

    .connect-message {
        margin-top: 10px;
        color: var(--black-eno);
        font-family: var(--grotesk-bold);
        font-size: 30px;
        text-align: center;
    }
}

@media (min-width: 1024px) and (max-width: 1366px) {

    p.install-metamask-container {
        background-color: var(--button-content);
        font-family: var(--grotesk-bold);
        font-size: 18px;
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }

    p.install-metamask-container:hover {
        background-color: var(--button-hover);
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }
}