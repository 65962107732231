/*==================== Import CSS Global Styles ====================*/
@import url(/src/components/globalStyles.css);

/*==================== BADGE MINT CARD ====================*/
.badge-mint-card {
    border: 1px solid #ccc;
    border-radius: 16px;
    color: var(--black-eno);
    padding: 16px;
    width: 100%;
    max-width: 350px;
    margin: auto;
    margin-bottom: 35px;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    transition: transform 0.3s ease;
}

p.title__badge {
    font-family: var(--grotesk-bold);
    font-size: 25px;
}

p.subtitle__badge {
    font-family: var(--grotesk-regular);
    font-size: 20px;
    color: var(--button-content);
    font-weight: 600;
}

.mint-info {
    width: 95%;
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.badge__description {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    font-size: 16px;
    font-family: var(--grotesk-regular);
    margin-bottom: 0px;
    line-height: 17px;
    color: var(--black-eno-mid);
}

.nft-preview {
    width: 100%;
    border-radius: 16px;
}

/*==================== BUTTONS ====================*/
.buttons__container{
    display: flex;
    
}

.hero__btn-mint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: 300% 100%;
    background-color: var(--button-content);
    border: 2px solid var(--main-color);
    border-radius: 32px;
    color: var(--main-color);
    cursor: pointer;
    font-family: var(--grotesk-regular);
    font-size: 22px;
    font-weight: 800;
    outline: none;
    padding: 10px 0;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    width: 300px;
}

.hero__btn-mint:hover {
    background-position: 100% 0;
    transition: all 0.4s ease-in-out;
    color: var(--main-color);
}

.hero__btn-mint.color-1:hover {
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
}

/*==================== BADGE GRID LIST ====================*/
.badge-grid-list {
    display: grid;
    gap: 20px;
    padding: 0 20px;
}
