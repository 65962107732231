/*==================== Import CSS Global Styles ====================*/
@import url(/src//components/globalStyles.css);

/* ================ Media Queries ================ */
.containerBridge{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.titleBridge {
    margin-bottom: 10px;
    font-size: 35px;
    font-family: var(--grotesk-bold);
}

button.bridge__btn-bridge {
    background-color: var(--shady-glade);
    padding: 10px 10px;
    border-radius: 32px;
    width: 150px;
    font-family: var(--grotesk-bold);
}

button.bridge__btn-bridge:hover {
    background-color: var(--button-hover);
}

.bridge__btn {
    background-size: 300% 100%;
    border-radius: 32px;
    cursor: pointer;
    color: var(--main-color);
    font-size: 25px;
    font-weight: 800;
    font-family: var(--grotesk-bold);
    padding: 10px 0;
    padding-top: 16px;
    margin-top: 50px;
    text-align: center;
    text-decoration: none;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    width: 350px;
}

.bridge__btn a {
    text-decoration: none;
    color: var(--main-color);
}

.bridge__btn:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.bridge__btn:focus {
    outline: none;
}

.bridge__btn.color-1 {
    background-color: transparent;
    border: 2px solid #fff;
}

.bridge__btn.color-1:hover {
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
}

button.max {
    background-color: var(--black-eno);
    border-radius: 40px;
    color: var(--main-bone);
    width: 80px;
    height: 80px;
    font-size: 20px;
    padding-top: 5px;
    font-family: var(--grotesk-bold);
    transition: .2s ease-in-out;
}

button.max:hover {
    background-color: var(--black-eno-mid);
}


.inputDestination{
    display: flex;
    background-color: var(--main-bone);
    border-radius: 32px;
    padding: 0px 0px 0px 10px;
    margin-top: 10px;
}
.inputDestination input{
    width: 70%;
    background-color:transparent;
    font-family: var(--grotesk-bold);
    font-size: 18px;
    padding: 10px 1px 0px;
    margin: 15px 0;
} 

.inputContainer{
    background-color: var(--main-bone);
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.inputContainer input{
    width: 70%;
    background-color:transparent;
    font-family: var(--grotesk-bold);
    font-size: 18px;
    padding: 5px 12px 0px;
    margin: 15px 0;
}

.network-warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.network-warning p {
    margin-top: 10px;
    color: var(--main-color);
    font-family: var(--grotesk-bold);
    font-size: 18px;
    text-align: center;
}

button.bridge__btn-bridge {
    background-color: var(--shady-glade);
    padding: 10px 10px;
    border-radius: 32px;
    width: 200px;
    font-family: var(--grotesk-bold);
    margin-top: 30px;
}

button.bridge__btn-bridge:hover {
    background-color: var(--button-hover);
}

.mainContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.sourceChainContainer,
.destinationChainContainer {
    background-color: var(--main-color);
    border-radius: 22px;
    border: 2px solid var(--main-bone);
    color: var(--black-eno);
    padding: 30px;
    width: 350px;
}

.balance-max-container {
    font-family: var(--grotesk-regular);
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.horizontal-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 24px;
}

.contentLabel {
    font-family: var(--grotesk-bold);
    font-size: 22px;
}

.selectBridge {
    color: var(--black-eno-mid);
}

/* .bridgeForm input[type="text"] {
    font-family: var(--grotesk-bold);
    font-size: 20px;
    padding: 10px 20px;
    padding-top: 20px;
    width: 100%;
    margin: 15px 0;
} */


@media (min-width: 1024px) {

    .containerBridge{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
    }

    .inputContainer {
        display: flex;
        background-color: var(--main-bone);
        border-radius: 64px;
        width: 100%;
        margin: 15px 0;
        justify-content: space-between;
    }

    .inputDestination{
        background-color: var(--main-bone);
        border-radius: 32px;
        margin: 15px 0;
        text-align: left;
    }
    .inputContainer input {
        width: 70%;
        background-color: transparent;
    }

    .inputContainer button {
        width: 20%;
    }

    .inputContainer input,
    .inputDestination input {
        width: 70%;
        background-color: transparent;
        font-family: var(--grotesk-bold);
        font-size: 40px;
        padding: 10px 20px 0px 15px;
        margin: 10px 0;
    }

    .inputAmount::placeholder {
        font-size: 30px;
    }

    #svg{
        color:red;
    }

    .inputContainer button,
    .inputDestination button {
        width: 20%;
    }

    button.max {
        background-color: var(--black-eno);
        border-radius: 50px;
        color: var(--main-bone);
        width: 90px;
        height: 90px;
        font-size: 20px;
        padding-top: 5px;
        font-family: var(--grotesk-bold);
        transition: .2s ease-in-out;
    }

    button.max:hover {
        background-color: var(--black-eno-mid);
    }

    .network-warning {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .network-warning p {
        margin-top: 10px;
        color: var(--main-color);
        font-family: var(--grotesk-bold);
        font-size: 30px;
        text-align: center;
    }

    .mainContainer {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .titleBridge {
        margin-bottom: 50px;
        font-size: 45px;
        font-family: var(--grotesk-bold);
    }

    .horizontal-layout {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        line-height: 25px;
    }

    .sourceChainContainer,
    .destinationChainContainer {
        background-color: var(--main-color);
        border-radius: 22px;
        border: 2px solid var(--main-bone);
        color: var(--black-eno);
        padding: 30px;
        width: 430px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .balance-max-container {
        font-family: var(--grotesk-regular);
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
    }

    .contentLabel {
        font-family: var(--grotesk-bold);
        font-size: 25px;
    }

    .selectBridge {
        color: var(--black-eno-mid);
    }

    /* 
    .bridgeForm input[type="text"] {
        font-family: var(--grotesk-bold);
        background-color: transparent;
        font-size: 30px;
        padding: 10px 20px;
        padding-top: 20px;
        width: 100%;
        margin: 15px 0;
    } */



    button.bridge__btn-bridge {
        background-color: var(--shady-glade);
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
        font-family: var(--grotesk-bold);
    }

    button.bridge__btn-bridge:hover {
        background-color: var(--button-hover);
    }

    .bridge__btn {
        background-size: 300% 100%;
        border-radius: 32px;
        cursor: pointer;
        color: var(--main-color);
        border: none;
        font-size: 25px;
        font-weight: 800;
        font-family: var(--grotesk-bold);
        padding: 10px 0;
        padding-top: 16px;
        margin-top: 50px;
        text-align: center;
        text-decoration: none;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        width: 350px;
    }

    .bridge__btn a {
        text-decoration: none;
        color: var(--main-color);
    }

    .bridge__btn:hover {
        background-position: 100% 0;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
    }

    .bridge__btn:focus {
        outline: none;
    }

    .bridge__btn.color-1 {
        background-color: transparent;
        border: 2px solid #fff;
    }

    .bridge__btn.color-1:hover {
        background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
        box-shadow: 0 0 15px 0 var(--golden-kiwi);
        border: 2px solid var(--golden-kiwi);
    }
}

/* ---------- Tablet ---------- */
@media (min-width: 768px) and (max-width: 1023px) {}

@media (max-width: 767px) {}