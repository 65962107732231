/*==================== Import CSS Global Styles ====================*/
@import url(/src/components/globalStyles.css);

/*==================== BADGE MINT CARD ====================*/
.badge-mint-card {
    border: 2px solid var(--bleached-aqua);
    border-radius: 16px;
    color: var(--black-eno);
    padding: 16px;
    width: 350px;
    max-width: 350px;
    margin: auto;
    margin-bottom: 35px;
    margin-top: 35px;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    transition: transform 0.3s ease;
}

.badge-mint-card:hover {
    transform: translate(0, -15px);
    border: 2px solid var(--button-content);
}

.nft-preview {
    width: 100%;
    border-radius: 16px;
}

/*==================== BUTTONS ====================*/
.hero__btn-mint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: 300% 100%;
    background-color: var(--button-content);
    border: 2px solid var(--main-color);
    border-radius: 32px;
    color: var(--main-color);
    cursor: pointer;
    font-family: var(--grotesk-regular);
    font-size: 22px;
    font-weight: 800;
    outline: none;
    padding: 10px 0;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    width: 100%;
}

.hero__btn-mint:hover {
    background-position: 100% 0;
    transition: all 0.4s ease-in-out;
    color: var(--main-color);
}

.hero__btn-mint.color-1:hover {
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--blue-vortex));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
}


button.install-metamask-container{
    background-color: var(--button-content);
    font-family: var(--grotesk-bold);
    font-size: 18px;
    padding: 10px 10px;
    border-radius: 32px;
    width: 200px;
}

button.connect__container-install{
    background-color: var(--button-content);
    font-family: var(--grotesk-bold);
    font-size: 18px;
    padding: 10px 10px;
    border-radius: 32px;
    width: 200px;   
}

@media (min-width: 1024px) and (max-width: 1366px) {

    p.install-metamask-container {
        background-color: var(--button-content);
        font-family: var(--grotesk-bold);
        font-size: 18px;
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }

    p.install-metamask-container:hover {
        background-color: var(--button-hover);
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }
}
