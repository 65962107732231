.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: var(--shady-glade);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
}

.back-to-top.show {
  opacity: 1;
  visibility: visible;
}

.back-to-top:hover {
  background-color: var(--button-hover);
  transform: translateY(-5px);
}

.ChevronIcon {
  color: var(--main-color);
  font-size: 20px;
}
