/*==================== Import CSS Global Styles ====================*/
@import url(/src/components/globalStyles.css);

.footer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    background-color: var(--main-color);
    justify-content: space-around;
}

.logo {
    row-gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo img {
    width: 40%;
}

.footer__left {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black-eno);
    height: 250px;
}

.footer__right {
    row-gap: 20px;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.span__footer {
    font-family: var(--grotesk-bold);
    color: var(--black-eno);
    font-size: 19px;
}

.enologo {
    display: flex;
}

.enologoNegativo {
    display: none;
}

.social__footer {
    display: flex;
    gap: 25px;
}

.social__footer {
    display: flex;
    justify-content: space-between;
}

.footer__link p {
    font-family: var(--text--subtitle);
    font-size: 1px;
}

.global__icons {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    gap: 10px;
}

.global__icons a {
    display: flex;
    justify-content: center;
    background-color: var(--black-eno);
    width: 38px;
    height: 30px;
    border-radius: 32px;
    color: var(--main-color);
    font-size: 1.2em;
    align-items: center;
    transition: all 0.4s ease-in-out;
}

.global__icons a:hover {
    background-color: var(--golden-kiwi);
    color: var(--black-eno);
}

.footer__link {
    display: flex;
    flex-direction: column;
    color: var(--black-eno);
    font-family: var(--grotesk-bold);
    font-size: 1.3em;
}

.name__link {
    display: flex;
    color: var(--black-eno);
    font-family: var(--grotesk-regular);
    font-size: 1.1em;
    justify-content: center;
}


.join__newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}

.span__section {
    color: var(--black-eno);
    font-size: 25px;
    font-family: var(--grotesk-bold);
    text-align: center;
}

.form__contact-form {
    display: flex;
    border-radius: 32px;
    width: 100%;
    justify-content: center;
    background-color: var(--gray-eno);
    padding: 5px 10px;
    margin-bottom: 20px;
}

.data__contact-form {
    display: flex;
    margin: 3;
}

input[type=text] {
    background-color: var(--gray-eno);
    color: var(--black-eno-mid);
    border-radius: 32px;
    margin: 10px;
    outline: none;
}

input[type=text]::placeholder {
    color: var(--black-eno-mid);
}

input[type=text]:focus {
    color: var(--black-eno);
}

button.submit__contact-form {
    background: var(--black-eno);
    border: transparent;
    color: var(--main-color);
    border-radius: 32px;
    cursor: pointer;
    font-family: var(--grotesk-bold);
    font-size: 16px;
    width: 120px;
    padding: 16px 0;
}

/* Responsive Styles */
@media (min-width: 768px) and (max-width: 1023px) {
    .footer__content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 100px;
    }

    .logo img {
        width: 40%;
    }

    .footer__right {
        row-gap: 20px;
        align-items: center;
        width: 45%;
    }

    .footer__left {
        display: flex;
        justify-content: center;
        padding: 50px 0;
        width: 45%;
    }

    .footer__link {
        font-size: 16px;
    }
}

@media (min-width: 1024px) {
    .footer__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 80%;
        background-color: var(--main-color);
        justify-content: space-around;
        padding: 30px 0;
    }

    .logo {
        row-gap: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .logo img {
        width: 40%;
    }

    .footer__left {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--black-eno);
        height: 250px;
    }

    .footer__right {
        row-gap: 20px;
        display: flex;
        flex-direction: column;
    }

    .span__footer {
        font-family: var(--grotesk-bold);
        color: var (--black-eno);
        font-size: 19px;
    }

    .footer__link {
        display: flex;
        flex-direction: column;
        color: var(--black-eno);
        font-family: var(--grotesk-bold);
        font-size: 1.3em;
    }

    .name__link {
        display: flex;
        color: var(--black-eno);
        font-family: var(--grotesk-regular);
        font-size: 1.1em;
        justify-content: center;
    }

    .global__icons {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        gap: 10px;
    }

    .global__icons a {
        display: flex;
        justify-content: center;
        background-color: var(--black-eno);
        width: 38px;
        height: 30px;
        border-radius: 32px;
        color: var(--main-color);
        font-size: 1.2em;
        align-items: center;
        transition: all 0.4s ease-in-out;
    }

    .global__icons a:hover {
        background-color: var(--golden-kiwi);
        color: var(--black-eno);
    }
}