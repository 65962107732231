/*==================== Import CSS Global Styles ====================*/
@import url(/src/components/globalStyles.css);

/*==================== BASE ====================*/
*,
*:before,
*:after {
    box-sizing: border-box;
}

.nft-purchase-container {
    background-color: var(--main-color); 
  }

/*==================== BUTTONS ====================*/
button.chakra-button.NFT__btn.color-1.css-moltat,
.hero__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: 300% 100%;
    background-color: var(--button-hover);
    border: 2px solid #fff;
    cursor: pointer;
    color: var(--main-color);
    font-size: 25px;
    font-weight: 800;
    font-family: var(--grotesk-regular);
    text-decoration: none;
    border-radius: 50px;
    transition: all 0.4s ease-in-out;
    outline: none;
    padding: 30px 0;
    width: 350px;
}

.hero__btn a {
    text-decoration: none;
    color: var(--main-color);
}

.hero__btn:hover,
button.chakra-button.NFT__btn.color-1.css-moltat:hover {
    background-position: 100% 0;
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
}

button.hero__btn.color-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: 300% 100%;
    background-color: transparent;
    border: 2px solid var(--black-eno);
    cursor: pointer;
    color: var(--black-eno);
    font-size: 25px;
    font-weight: 800;
    font-family: var(--grotesk-regular);
    text-decoration: none;
    border-radius: 50px;
    transition: all 0.4s ease-in-out;
    outline: none;
    padding: 10px 0;
    width: 310px;
    padding-bottom: 5px;
}

button.hero__btn.color-1:hover {
    border: 2px solid var(--golden-kiwi);
    color: var(--main-color);
}

/*==================== SECTION ====================*/
.hero__NFT__title {
    margin-top: 35px;
    color: var(--black-eno);
    font-size: 28px;
    text-align: center !important;
    font-family: var(--grotesk-bold);
    /* background-image: linear-gradient(to right, var(--chalk-violet) 30%, var(--bleached-aqua) 40%, var(--contract-color) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.text__NFT__subtitle {
    color: var(--black-eno);
    font-family: var(--grotesk-regular);
    font-size: 18px;
    margin-top: 20px;
    padding: 0px 15px;
    text-align: center;
}

.section__right {}

.section__right video {
    margin-top: 20px;
    border-radius: 32px;
}

/*==================== BADGES CONTAINER ====================*/
.about__nft {
    font-size: 22px;
    font-weight: 900;
    font-family: var(--grotesk-bold);
    margin-top: 22px;
    margin-right: 10px;
}

.details__nft {
    font-size: 18px;
    font-family: var(--grotesk-medium);
    color: var(--black-eno-mid);
    line-height: 22px;
}

.badges__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-color);
    padding-bottom: 90px;
}

.container__NFT__right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container__NFT__right video {
    display: flex;
    justify-content: center;
    border-radius: 32px;
    margin-top: 50px;
    width: 50%;
}

.NFT__container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.text__NFT__subtitle,
.hero__NFT__title {
    color: var(--black-eno);
}

.NFT__button {
    display: flex;
    justify-content: center;
}

/*==================== NFT BUTTON ====================*/
.badges__container {
    background-color: var(--main-color);
}

.NFT__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: 300% 100%;
    border: 2px solid var(--black-eno);
    cursor: pointer;
    color: var(--black-eno);
    font-size: 25px;
    font-weight: 800;
    font-family: var(--grotesk-regular);
    text-decoration: none;
    border-radius: 50px;
    transition: all 0.4s ease-in-out;
    outline: none;
    padding: 15px 0;
    width: 350px;
}

.NFT__btn a {
    text-decoration: none;
    color: var(--main-color);
}

.NFT__btn:hover,
button.chakra-button.NFT__btn.color-1.css-moltat:hover {
    background-position: 100% 0;
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var (--legion-blue));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
    color: var(--main-color);
}

/*==================== NFT DETAIL VIEW ====================*/
.nft-detail-container {
    display: none;
    flex-direction: column;
}

.nft-detail-container a {
    cursor: pointer;
}

.nft-detail-container-mobile {
    display: flex;
    flex-direction: column;
}

.nft-detail-container-mobile a {
    cursor: pointer;
}

.nft-detail-video {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nft-detail-video video {
    width: 100%;
}

.nft-detail-content {
    display: flex;
    align-self: center;
    flex-direction: column;
    padding: 30px;
    margin: -30px 0 0 0;
    height: 60%;
    background-color: white;
    border-radius: 36px 36px 0 0;
    z-index: 1;
}

.video-container {
    margin-top: 0;
    padding-top: 0;
}

.NFT__title {
    color: var(--black-eno);
    font-size: 35px;
    font-family: var(--grotesk-bold);
    font-weight: 700;
    text-align: left;
}

.NFT__description {
    color: var(--black-eno);
    font-size: 16px;
}


.nft-detail-title {}

.nft-detail-description {}

.nft-detail-content button {
    margin-top: 30px;
}

.NFT__line-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.NFT__line {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9d9d9ae;
    width: 35%;
    height: .4rem;
    border-radius: 30px;
}

.NFT__btnETH {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

a.getNFT {
    width: 100%;
    color: var(--main-color);
    font-family: var(--grotesk-bold);
    font-size: 20px;
}

/*==================== NFT BUTTONS ====================*/
.NFT__buttons {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 20px;
}

.NFT__buttons-mobile {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
}

p.purchase__minted-details-mobile {
    display: flex;
    font-size: 16px;
    flex-grow: 1;
    flex-direction: row;
    font-family: var(--grotesk-medium);
    font-weight: 600;
    margin-bottom:3px;
    color: var(--button-hover);
}

.NFT__Container-Editions {
    padding-top: 10px;
}

.EnoPrice img {
    width: 30px;
    height: 30px;
    border: 2px solid var(--black-eno-mid);
    background-color: var(--main-color);
    border-radius: 32px;
}

p.EnoPrice {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.details__nft {
    font-size: 18px;
    font-family: var(--grotesk-regular);
    color: var(--black-eno-mid);
    margin-top: 10px;
}

p.text__subtitle-NFT {
    font-size: 18px;
    font-family: var(--grotesk-regular);
    color: var(--black-eno-mid);
    margin-top: 10px;
    line-height: 20px;
}


.NFT__content {
    display: flex;
    gap: 20px;
    font-size: 20px;
    font-weight: 800;
    color: var(--button-content);
    text-align: left;
    flex-direction: row;
}




/*==================== LOADER ====================*/
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #040f1350;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loader-overlay video {
    border: 4px solid var(--main-color);
    border-radius: 32px;
    animation: blink 4s linear infinite;
}

@keyframes blink {
    0% {
        border-color: var(--main-color);
    }

    25% {
        border-color: var(--golden-kiwi);
    }

    50% {
        border-color: var(--chalk-violet-strong);
    }

    75% {
        border-color: var(--contract-color);
    }

    100% {
        border-color: var(--main-color);
    }
}


.blurred {
    filter: blur(5px);
    pointer-events: none;
}

/*==================== BACK BUTTON ====================*/
.backButton__Container {
    display: none;
}

.backButton__right {
    display: none;
}

.backButton__left {
    display: none;
}

.back__button {
    display: flex;
    justify-content: center;
    background-color: var(--main-color);
    color: var(--black-eno);
    border-radius: 64px;
    width: 40px;
    height: 40px;
    margin: 30px 0 0 30px;
    position: absolute;
    align-items: center;
    z-index: 1;
    transition: all 0.4s ease-in-out;
}

.back__button:hover {
    background-color: var(--golden-kiwi);
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
}

.back__button a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: var(--golden-kiwi);
}

.back__buttonDesktop {
    display: flex;
    font-size: 22px;
    border-radius: 32px;
    width: 35px;
    height: 35px;
    background-color: var(--main-color);
    color: var(--black-eno);
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
}

.back__buttonDesktop:hover {
    background-color: var(--golden-kiwi);
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
}

.text-subtitle-NFT-mobile{
    text-align: center;
    font-size: 18px;
    font-family: var(--grotesk-medium);
    font-weight: 600;
    padding: 20px 0;
    margin-top: 10px;
}

.NFT-mobile{
    display: inline;
    width: 85%;
    max-width: 350px;
}

.newsletter-NFT__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
/*==================== NEWSPAPER ====================*/
.newspaper__right-NFT {
    display: flex;
    justify-content: center;
}

.newspaper-NFT {
    background-color: var(--main-color);
    color: var(--black-eno);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.newspaper-NFT h2 {
    color: var(--black-eno);
}

.badges-mobile {
    display: inline;
    width: 85%;
    max-width: 350px;
}

.badges-desktop {
    display: none;
}

.title-NFT-mobile {
    color: var(--black-eno);
    font-size: 28px;
    font-family: var(--grotesk-bold);
}

.component__container{
    background-color: var(--main-color);
}

/* ==================== MEDIA QUERIES ==================== */
/* Desktop */
@media (min-width: 1024px) {

    .nft-detail-container {
        display: flex;
        background-color: transparent;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: var(--main-color);
        padding-top: 150px;
        border-radius: 10px;
    }

    .nft-detail-container-mobile {
        display: none;
    }


    .nft-detail-video {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 35% !important;
    }

    .nft-detail-video video {
        border-radius: 32px;
        border: 3px solid var(--bleached-aqua);
        -webkit-box-shadow: 10px 10px 71px -3px rgba(255, 255, 255, 1);
        -moz-box-shadow: 10px 10px 71px -3px rgba(255, 255, 255, 1);
        box-shadow: 10px 10px 71px -3px rgba(138, 138, 138, 0.69);
    }

    .nft-detail-content {
        display: flex;
        background-color: transparent;
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
        height: 70vh;
        width: 45%;
        margin-left: 40px;
        justify-content: center;
    }

    .NFT__line-container {
        display: none;
    }

    .NFT__description {
        display: flex;
        text-align: left;
        color: var(--main-color);
        font-size: 18px;
        font-family: var(--grotesk-regular);
        flex-direction: column;
    }

    .NFT__content {
        font-family: var(--grotesk-regular);
        color: var(--main-color);
    }

    .nft-detail-title {
        font-size: 2em;
        margin-bottom: 10px;
    }

    /* ================= Nuevos Estilos ================= */

    .newspaper-NFT {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        margin: 250px 0px -50px 0;
    }

    .newspaper-NFT-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 30%;
    }

    .title-NFT {
        font-size: 36px;
        font-family: var(--grotesk-semi-bold);
    }


    .text-subtitle-NFT {
        font-size: 18px;
        font-family: var(--grotesk-medium);
        line-height: 22px;
        color: var(--main-color);
    }

    .newspaper-NFT-right video {
        width: 100%;
        height: auto;
        position: absolute;
        z-index: 1;
        mix-blend-mode: multiply;
    }

    .newspaper-NFT-right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 450px;
        background: url(/src/assets/Back_NFTBadges.png) no-repeat center center;
        background-size: contain;
        position: relative;
        z-index: 1;
    }

    .layer-right {
        background: none !important;
        height: auto !important;
        padding: 0 !important;
        width: 100%;
        position: absolute;
        z-index: 2;
    }

    .title-NFT-mobile,
    .text-subtitle-NFT-mobile {
        display: none;
    }

    video.layer.NFT-mobile{
        display: none;
    }

    .newspaper-NFT{
        background-color: transparent;
    }

    /* ================= Nuevos Estilos ================= */

    .nft-detail-description {}

    .NFT__ContainerBtn {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-top: 50px;
    }

    .back__button {
        display: none;
    }

    .backButton__Container {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 16px;
    }

    .backButton__right h2 {
        font-size: 22px;
        color: var(--black-eno);
    }

    .hero__container {
        padding: 50px 0;
        display: flex;
        justify-content: center;
    }

    .newspaper-NFT button {
        margin-top: 20px;
    }

    .newspaper-NFT p {
        font-size: var(--text--subtitle);
    }

    .container {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }

    /* ------------ NFT button -----------  */
    button.chakra-button.NFT__btn.color-1.css-moltat,
    .hero__btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-size: 300% 100%;
        background-color: transparent;
        border: 2px solid var(--black-eno);
        cursor: pointer;
        color: var(--black-eno);
        font-size: 25px;
        font-weight: 800;
        font-family: var(--grotesk-regular);
        text-decoration: none;
        border-radius: 50px;
        transition: all 0.4s ease-in-out;
        outline: none;
        padding: 25px 0;
        width: 250px;
    }

    .hero__btn:hover,
    button.chakra-button.NFT__btn.color-1.css-moltat:hover {
        background-position: 100% 0;
        background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
        box-shadow: 0 0 15px 0 var(--golden-kiwi);
        border: 2px solid var(--golden-kiwi);
    }

    .NFT__container button:hover {
        color: var(--main-color) !important;
    }

    .nft-detail-content button {
        margin-top: 0;
    }

    .NFT__Container-Btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

    }

    .NFT__btnENO {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .NFT__Container-Editions {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* ------------- Badges Container ------------- */
    .badges__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin-bottom: 150px;
        padding: 0 180px;
    }

    .NFT__title {
        font-size: 45px;
        color: var(--black-eno);
        font-family: var(--grotesk-regular);
    }

    .about__nft {
        display: none;
    }

    .details__nft {
        font-size: 23px;
        font-family: var(--grotesk-regular);
        color: var(--black-eno-mid);
        line-height: 25px;
        margin-top: 10px;
    }

    .NFT__btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-size: 300% 100%;
        border: 2px solid var(--main-color);
        cursor: pointer;
        color: var(--main-color);
        font-size: 25px;
        font-weight: 800;
        font-family: var(--grotesk-regular);
        text-decoration: none;
        border-radius: 50px;
        transition: all 0.4s ease-in-out;
        outline: none;
        padding: 15px 0;
        width: 350px;
    }

    .NFT__btn:active {
        color: var(--main-color);
    }

    .hero__NFT__title {
        text-align: left;
        color: var(--main-color);
    }

    .text__NFT__subtitle {
        color: var(--main-color);
        font-size: 22px;
        text-align: left;
    }

    .container__NFT__left {
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: flex-start;
    }

    .container__NFT__right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
    }

    .container__NFT__right video {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        width: 65%;
    }

    .backButton__Container {
        display: flex;
    }

    .backButton__right,
    .backButton__left {
        display: block;
    }


    /* ---------------- NFT Right & Left ---------------- */

    .newspaper-NFT {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 130px 0;
    }

    .newspaper-NFT video {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0;
    }

    .newspaper__right-NFT {
        width: 35%;
        display: flex;
        justify-content: center;
    }

    .newspaper__left-NFT {
        display: flex;
        width: 35%;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
    }

    .newspaper-NFT button {
        margin-top: 20px;
    }

    .newspaper-NFT p {
        font-size: 18px;
    }

    p.text__subtitle-NFT {
        font-size: 22px;
        font-family: var(--grotesk-regular);
        color: var(--main-color);
    }

    /* ---------------- End NFT Right & Left ---------------- */

    .newspaper-NFT {
        display: none;
        /* flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        margin: 250px 0px -50px 0; */
    }

    .newspaper-NFT h2 {
        color: var(--main-color);
    }



    .layer {
        background: none;
        height: auto;
        padding: 0;
        width: 100%;
        z-index: 0;
    }

    .badges-desktop {
        display: flex;
    }

    video.layer.badges-mobile {
        display: none;
    }

    .EnoPrice img {
        width: 35px;
        height: 35px;
        border: 2px solid var(--black-eno-mid);
        background-color: var(--main-color);
        border-radius: 32px;
    }

    p.EnoPrice {
        color: var(--black-eno);
        gap: 10px;
        display: flex;
        align-items: flex-end;
    }

    p.purchase__minted-released {
        display: none;
    }

    p.purchase__minted-mobile {
        display: flex;
        font-size: 18px;
        flex-grow: 1;
        flex-direction: row;
        font-weight: 600;
        font-family: var(--grotesk-medium);
        color: var(--black-eno);
    }

    .component__container{
        display: flex;
        background-color: transparent;
        align-items: center;
        flex-direction: column;
        background-color: transparent;
    }
}

@media (max-width: 1024px) {

    .nft-detail-content {}

    .details__nft {
        font-size: 18px;
        font-family: var(--grotesk-regular);
        color: var(--black-eno-mid);
        line-height: 20px;
        margin-top: 10px;
    }

    .NFT__title {
        padding-top: 20px;
        font-size: 30px;
    }

    .NFT__ContainerBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }

    .nft-detail-video {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }

    .NFT__btnENO {}

    button.chakra-button.NFT__btn.color-1.css-moltat,
    .hero__btn {
        width: 300px;
    }
}
