/*==================== Import CSS Global Styles ====================*/
@import url(/src/components/globalStyles.css);

/*==================== ENO BADGES ====================*/

.hero__titleWhite{
    color: var(--black-eno);
    font-size: 25px;
    text-align: center !important;
    font-family: var(--grotesk-bold);
    margin-top: 50px;
}

.nft-purchase-container {
    background-color: var(--main-color);
  }

.EnoBadges {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.newspaper__right {
    display: flex;
    justify-content: center;
}

a.button__NFT {
    display: flex;
    align-items: center;
    justify-content: center;
}

a.button__NFT-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
}

a.button__NFT-desktop {
    display: none;
}

.hero__btn-alternate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: 300% 100%;
    background-color: transparent;
    border: 2px solid var(--main-color);
    border-radius: 50px;
    color: var(--main-color);
    cursor: pointer;
    font-family: var(--grotesk-regular);
    font-size: 22px;
    font-weight: 800;
    margin-top: 40px;
    outline: none;
    padding: 10px 0;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    width: 350px;
}

.hero__btn-alternate:hover {
    background-position: 100% 0;
    transition: all 0.4s ease-in-out;
    color: var(--main-color);
}

.hero__btn-alternate.color-1:hover {
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
}

.EnoBadgesNFT {
    display: grid;
    align-items: center;
    width: 90%;
    justify-items: center;
    margin: 50px auto;
    background: url('/src/assets/badgesNFT.png') 0% 0% / contain no-repeat;
}

.backBadges {
    display: flex;
    justify-content: center;
    width: 85%;
    margin-top: 30px;
}

button.connect-container-btn {
    background-color: var(--button-content);
    font-family: var(--grotesk-bold);
    font-size: 18px;
    padding: 10px 10px;
    border-radius: 32px;
    width: 200px;
}

button.connect-container-btn:hover {
    background-color: var(--button-hover);
    padding: 10px 10px;
    border-radius: 32px;
    width: 200px;
}

/*==================== MEDIA QUERIES ====================*/
/* Tablet Vertical */
@media (min-width: 768px) and (max-width: 1024px) {}

/* Tablet Horizontal */
@media (min-width: 1024px) and (max-width: 1366px) {
    .hero__title h2 {
        text-align: left;
    }

    .hero__title {
        font-size: 25px;
        text-align: left !important;
    }

    .hero__btn-alternate {
        font-size: 18px;
        width: 250px;
        border: 2px solid var(--main-color);
    }

    .text__subtitle {
        font-size: 16px;
    }

    button.connect-container-btn {
        background-color: var(--button-content);
        font-family: var(--grotesk-bold);
        font-size: 18px;
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }

    button.connect-container-btn:hover {
        background-color: var(--button-hover);
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }

}
/* Desktop */
@media (min-width: 1024px) {
    .EnoBadges {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 100px 0;
        width: 100%;
        background: transparent;
    }

    .EnoBadges video {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0;
    }

    .EnoBadges__right {
        width: 35%;
        display: flex;
        justify-content: center;
    }

    .EnoBadges__left {
        display: flex;
        width: 30%;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
    }

    .EnoBadges button {
        margin-top: 20px;
    }

    .EnoBadges p {
        font-size: var(--text--subtitle);
    }

    .text__subtitle {
        font-size: 22px;
        font-family: var(--grotesk-regular);
        color: var(--main-color);
    }

    .EnoBadgesNFT {
        display: grid;
        align-items: center;
        width: 100%;
        justify-items: center;
        margin: 0px auto;
        background: url('/src/assets/badgesNFT.png') 0% 0% / contain no-repeat;
    }

    .backBadges {
        align-items: center;
        display: grid;
        justify-items: center;
        margin: 0 auto;
        background-repeat: no-repeat;
        max-width: 300px;
        background: url(/src/assets/badgesNFT.png) no-repeat center center;
        background-size: cover;
    }

    .layer {
        background: none !important;
        height: auto !important;
        padding: 0 !important;
        width: 100%;
        z-index: -1;
    }

    .hero__btn-alternate {
        font-size: 22px;
        width: 350px;
        border: 2px solid var(--main-color);
        color: var(--main-color);
    }

    a.button__NFT-desktop {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a.button__NFT-mobile {
        display: none;
    }

    .connect-container-btn {
        background-color: var(--button-content);
        font-family: var(--grotesk-bold);
        font-size: 18px;
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }

    .connect-container-btn:hover {
        background-color: var(--button-hover);
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }

}

/* Desktop Large */
@media (min-width: 1200px) {

    button.connect-container-btn {
        background-color: var(--button-content);
        font-family: var(--grotesk-bold);
        font-size: 18px;
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }

    button.connect-container-btn:hover {
        background-color: var(--button-hover);
        padding: 10px 10px;
        border-radius: 32px;
        width: 200px;
    }
}