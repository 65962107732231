/*==================== Import CSS Global Styles ====================*/
@import url(/src//components/globalStyles.css);
/* ==================== Clases ==================== */

.bulletContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
}

.bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #b6b6b6;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.bullet.active {
  background-color: var(--black-eno-mid);
}

.latestContainer {
  background-color: var(--main-bone);
  border-radius: 32px;
  padding: 20px 20px 50px 20px;
}

.latestNews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.headerContainer {
  background-color: var(--main-bone); /* Aplicar color base */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 32px;
  width: 100%;
}

.headerContainer h2 {
  font-size: 20px;
  font-family: var(--grotesk-bold);
  color: var(--black-eno); /* Color de texto */
}

.viewAllBlogsBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--shady-glade);
  border-radius: 22px;
  color: var(--main-bone);
  font-family: var(--grotesk-semi-bold);
  font-size: 16px;
  padding: 10px 0;
  width: 150px;
  transition: all 0.2s ease-in-out;
}

.viewAllBlogsBtn:hover {
  background-color: var(--button-hover);
  color: #fff;
}

.postsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid var(--gray-eno);
  border-radius: 16px;
  padding: 15px;
  background-color: var(--main-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  width: 350px;
  min-height: 520px;
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: calc(33.333% - 40px);
  cursor: grab;
}

.postImage {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: 180px;
}

.postTitle {
  font-family: var(--grotesk-bold);
  color: var(--black-eno);
  font-size: 16px;
  line-height: 1.2;
  margin-top: 10px;
  text-align: left;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex-grow: 1;
}

.postDate {
  display: flex;
  background-color: var(--chalk-violet);
  border-radius: 16px;
  color: var(--main-color);
  font-weight: 700;
  font-size: 14px;
  font-family: var(--grotesk-regular);
  letter-spacing: 1px;
  margin-top: 15px;
  height: 40px;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.postExcerpt {
  margin: 8px 0;
  color: var(--black-eno-mid);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex-grow: 1;
}

.heroBtn {
  cursor: pointer;
  border: none;
  background-size: 300% 100%;
  width: 100%;
  font-size: 18px;
  font-weight: 800;
  font-family: var(--grotesk-bold);
  color: var(--black-eno);
  text-align: center;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.4s ease-in-out;
}

.heroBtn a {
  text-decoration: none;
  color: var(--main-color);
}

.heroBtn:hover {
  background-position: 100% 0;
  transition: all 0.4s ease-in-out;
}

.heroBtn:focus {
  outline: none;
}

.heroBtnColor {
  background-color: transparent;
  border: 2px solid var(--black-eno);
  color: var(--black-eno);
  padding: 5px 0px 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroBtnColor:hover {
  background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
  box-shadow: 0 0 15px 0 var(--golden-kiwi);
  border: 2px solid var(--golden-kiwi);
  color: var(--main-color);
}

.btnContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
}

/* Media Queries */
@media (min-width: 1024px) {
  .latestNews {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 32px;
    max-width: 1920px;
    margin: 0 auto;
  }

  .latestContainer {
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px 25px 50px 25px;
  }

  .headerContainer {
    padding-top: 20px;
    width: 100%;
    max-width: 1920px;
  }

  .headerContainer h2 {
    font-family: var(--grotesk-bold);
    font-size: 35px;
    color: var(--black-eno);
  }

  .postsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 32px;
    gap: 20px;
  }

  .post {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    font-family: var(--grotesk-regular);
    margin-bottom: 0px;
    line-height: 17px;
    border-radius: 25px;
    width: 360px;
    color: var(--black-eno-mid);
    min-height: 500px;
    cursor: pointer;
  }

  .postImage {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    height: 200px;
  }

  .postTitle {
    font-family: var(--grotesk-bold);
    color: var(--black-eno);
    font-size: 20px;
    line-height: 1.2;
    margin-top: 20px;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex-grow: 1;
  }

  .postDate {
    display: flex;
    background-color: var(--chalk-violet);
    border-radius: 16px;
    color: var(--main-color);
    font-weight: 700;
    font-size: 16px;
    font-family: var(--grotesk-regular);
    letter-spacing: 1px;
    margin-top: 15px;
    height: 35px;
    width: 45%;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
  }

  .heroBtnColor {
    background-color: transparent;
    border: 2px solid var(--black-eno);
    color: var(--black-eno);
    padding: 5px 0px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heroBtnColor:hover {
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
    color: var(--main-color);
  }

  .btnContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .bulletContainer {
    display: none;
  }
}
